@import "./../../../../styles/variables";
@mixin deathVerificationCommons() {
  @media (max-width: 765px) {
    //width: 319px;
  }
  font-family: $font;
  color: $textColor;
}

.deathverification-heading {
  @include deathVerificationCommons();
  font-size: 32px;
  font-weight: bold;
  padding-top: 64px;
  letter-spacing: 0;
  padding-bottom: 16px;
}

.deathverification-content {
  @include deathVerificationCommons();
  color: $textColor;
  font-family: $font;
  font-size: 16px;
  font-weight: 500;
  padding-top: 30px;
}
.deathverification-info {
    @include deathVerificationCommons();
    font-size: 24px;
    font-weight: 500;

  }
.deathverification-info1{
    @include deathVerificationCommons();
    font-size: 16px;
    font-weight: 500;
    padding-top: 20px;
}
.DoD-Range{
    padding-bottom: 20px;   
}

@media (min-width: 765px) {
    .deathVerfiyDateRange{
        width: 400px;
    }
}