

.lg-container {
  padding: 0 24px 0 24px;
}

@media (min-width:765px) {
  .lg-container {
   width: 684px;
  }
}
