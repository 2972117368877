@import "./../../../../styles/variables";

.birth-heading {
  color: $textColor;
  font-family: $font;
  font-size: 32px;
  font-weight: bold;
  padding-top: 64px;
  letter-spacing: 0;
  padding-bottom: 16px;
}

.sub-get-started {
  color: $textColor;
  font-family: $font;
  font-size: 24px;
  font-weight: 500;
  padding-top: 25px;
  padding-bottom: 12px;
}
.birth-content-section {
  padding-top: 32px;
}

.select-align {
  padding-bottom: 48px;
}

.birth-date {
  padding-bottom: 48px;
}

.button,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  height: 64px;
  width: 400px;
  @media (max-width: 765px) {
    width: 352px;
  }
  border: 2px solid $checkboxBorder;
  border-radius: 4px;
}

select,
input {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  height: 64px;
  width: 400px;
  @media (max-width: 765px) {
    width: 100%;
  }
  border: 2px solid $checkboxBorder;
  border-radius: 4px;
}

.error-color {
  color: red;
}

.btn-birth {
  text-align: center;
  padding: 64px 0 104px 0;
}
@media (min-width: 765px) {
  .birth-content-section {
    width: 400px;
  }

  .next-button {
    height: 56px;
    width: 160px;
    margin-top: 0;
  }
  .back-button {
    height: 56px;
    width: 160px;
  }
}
