// FOOTER POSITIONING
.main-container {
  min-height: 100vh;
  overflow: hidden;
  display: block;
  position: relative;
  padding-bottom: 100px;
}

footer {
  @media (min-width: 765px) {
    position: absolute;
  }
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;

  width: 100%;
}

.footer-padding {
  @media (max-width: 765px) {
    padding-top: 320px;
  }
}
//  END FOOTER POSITIONING

//  GLOBAL MODAL
.modal-header {
  border-bottom: none;
}
.modal-dialog {
  max-width: 854px;
  @media (max-width: 765px) {
    margin: 0;
  }
}
.modal-content{
  box-shadow: 0px 81px 56px -56px #042B5A;

}
//  END GLOBAL MODAL

@import "./styles/variables";

@mixin customButtons() {
  height: 56px;
  width: 136px;
  @media (max-width: 765px) {
    height: 60px;
    width: 248px;
    margin-top: 20px;
  }
  border-radius: 40px;
  text-decoration: none;
  font-family: "Work Sans";
  font-size: 16px;
  text-align: center;
  margin: auto;
  vertical-align: middle;
  padding-top: 14px;
  display: inline-block;
  cursor: pointer;
}

.answer {
  padding-left: 14px;
}

@mixin buttonCommons() {
  @media (max-width: 765px) {
    height: 60px;
    width: 248px;
    margin-top: 20px;
  }
  border-radius: 40px;
  border: none;
  font-family: $font;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 30px;
  text-align: center;
}

@mixin blueButtonCommons() {
  @media (max-width: 765px) {
  }
  border-radius: 40px;
  border: none;
  font-family: $font;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 30px;
  color: $textColor;
}

.primary-blue-button {
  @include blueButtonCommons();
  @media (max-width: 765px) {
    height: 60px;
    width: 248px;
    margin-top: 20px;
  }
  box-sizing: border-box;
  background-color: $buttonBlue;
  color: $buttonWhite;
  margin-top: 16px;
  @media (min-width: 765px) {
    margin-left: 50px;
  }
}

.select-input-field-align {
  @media (min-width: 765px) {
    width: 400px;
    padding-top: 24px;
  }
  @media (max-width: 765px) {
    width: 327px;
    padding-top: 20px;
  }
}
.url-links {
  text-decoration: none;
  color: red;
}

.input-date {
  display: block;
  box-sizing: border-box;
  border-radius: 4px;
  border: 2px solid #c2c6cc;
  padding: 8px 16px;
  font-family: $font;
  font-size: 16px;
  font-weight: bold;
  color: $textColor;

  // @media (max-width: 765px) {
  //   width: 327px !important;
  // }
}

.next-button {
  @include buttonCommons();
  box-sizing: border-box;
  background-color: $buttonBlue;
  color: $buttonWhite;
}
.back-button {
  @include buttonCommons();
  color: $buttonBlue;
  border: 2px solid $buttonBlue;
  background-color: $buttonWhite;
}

.remove-button {
  background-color: $buttonWhite;
  color: #d2082d;
  border: 2px solid #d2082d;
  margin-bottom: 20px;
  border-radius: 40px;
  margin-left: 0;
  height: 56px;
  width: 160px;
}

.error-red-content {
  background-color: #d2082d;
  color: white;
  width: inherit;
  height: inherit;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  padding-left: 10px;
  padding-top: 5px;
  align-items: center;
  padding-bottom: 10px;
  padding-right: 10px;
}

.error-red-content.fetal-death {
  width: 188px;
}

.info-input-align.fetal-death {
  padding-bottom: 0px !important;
};

.error-red {
  border-color: #d2082d;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  outline: none;
}

.css-1wa3eu0-placeholder {
  font-family: "Work Sans";
  color: #575757 !important;
  margin-top: 18px;
}

.css-1wa3eu0-placeholder,
.css-b8ldur-Input .css-b8ldur-Input input {
  min-height: 56px;
}

.css-yk16xz-control {
  border: 2px solid #c2c6cc !important;
  font-weight: 700;
}
.css-g1d714-ValueContainer {
  margin-left: 8px;
  min-height: 56px;
  font-weight: bold;
  position: initial;
}
.error-red .css-yk16xz-control {
  border: 2px solid #d2082d !important;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.error-info-color {
  color: $errorinfocolor;
}
.optional-text {
  color: #304ffe;
  padding-left: 10px;
}

.date-field {
  padding-bottom: 48px;
}

.verify-error-message {
  box-sizing: border-box;
  @media (min-width: 784px) {
    width: 624px;
  }
  border: 2px solid #f8bb40;
  border-radius: 4px;
  background-color: #ffffff;
  margin-bottom: 54px;
}

.verify-error-content {
  padding: 24px 0 8px 24px;
}
.verify-alert-icon {
  @media (min-width: 784px) {
    padding: 60px 10px 30px 35px;
  }
  background-color: #f8bb40;
  align-items: center;
}

.verify-error-text {
  color: #283847;
  font-family: "Work Sans";
  font-size: 16px;
  font-weight: bold;
}

.verify-error-desc {
  color: #47525d;
  font-family: "Work Sans";
  font-size: 16px;
  font-weight: 500;
}

.verify-close {
  @media (min-width: 784px) {
    padding-left: 75px;
  }
}
.input-date {
  border: 2px solid #c2c6cc !important;
}
.error-red.input-date {
  border: 2px solid #d2082d !important;
  border-bottom-left-radius: 0px;

  border-bottom-right-radius: 0px;
  @media (max-width: 765px) {
    width: 100% !important;
  }
}

.error-info-color {
  color: $errorinfocolor;
}

.verify-error-message {
  // display: inline-block;
  box-sizing: border-box;
  @media (min-width: 784px) {
    // height: 112px;
    width: 624px;
  }
  border: 2px solid #f8bb40;
  border-radius: 4px;
  background-color: #ffffff;
  margin-bottom: 54px;
}

.verify-error-content {
  padding: 24px 0 8px 24px;
}
.verify-alert-icon {
  @media (min-width: 784px) {
    padding: 60px 10px 30px 35px;
  }
  vertical-align: baseline;
  background-color: #f8bb40;
  align-items: center;
}

.verify-error-text {
  color: #283847;
  font-family: "Work Sans";
  font-size: 16px;
  font-weight: bold;
}

.verify-error-desc {
  color: #47525d;
  font-family: "Work Sans";
  font-size: 16px;
  font-weight: 500;
}

.verify-close {
  @media (min-width: 784px) {
    padding-left: 75px;
  }
}

.css-yk16xz-control {
  @media (max-width: 765px) {
    width: 100%;
  }
  @media (min-width: 765px) {
    width: 400px;
  }
}
// REUSABLE BUTTON PRIMARY
.button-primary {
  @include customButtons();
  background-color: #304ffe;
  border: 1px solid #304ffe;
  color: #ffffff;
}

.button-primary:hover {
  background-color: #0b26be;
  text-decoration: none;
  color: #ffffff;
}
.button-primary:active {
  position: relative;
  top: 1px;
}

// REUSABLE BUTTON SECONDARY
.button-secondary {
  @include customButtons();
  background-color: #ffffff;
  border: 1px solid #304ffe;
  color: #304ffe;
  font-family: "Work Sans";
}

.button-secondary:hover {
  background-color: #0b26be;
  text-decoration: none;
  color: #ffffff;
}
.button-secondary:active {
  position: relative;
  top: 1px;
}

.form-checkbox-default {
  height: auto;
  padding-bottom: 65px;
  padding-top: 20px;
  @media (max-width: 765px) {
    margin-top: 30px;
    padding-top: 30px;
  }
}

.form-checkbox-heading {
  height: auto;
  padding-bottom: 30px;
  padding-top: 20px;
  @media (max-width: 765px) {
    margin-top: 30px;
    padding-top: 30px;
  }
}
.checkbox-parent {
  padding-top: 4px;
}

.info-input-align {
  padding-bottom: 10px;
}

.death-gender-input-align{
  padding-bottom: 24px;
}

.birth-content {
  color: $textColor;
  font-family: $font;
  font-size: 16px;
  font-weight: 500;
  padding-top: 5px;
  @media (max-width: 780px) {
    width: 100%;
  }
}

.captcha-info-container {
  border: 1px solid #dcdee1;
  border-radius: 2px;
  background-color: #ffffff;
  box-shadow: inset 0 1px 0 0 #dcdee1;
  margin: 0px 1px 20px 3px;
  padding: 30px 0 30px 15px;
  margin-top: 41px;
}

.captcha-validation-container {
  padding-left: 20px;
  font-weight: bold;
  padding-top: 3px;
}

.captcha-img-container {
  float: right;
  height: 65px;
}

.field-label-padding {
  padding-top: 8px;
}

.form-input-padding {
  padding-bottom: 8px;
}

.btn-group-align {
  padding: 64px 0 104px 0;
  @media (max-width: 765px) {
    text-align: center;
  }
}

.get-state-birth{
  @media (min-width: 780px) {
    width: 400px;
  }
  @media (max-width: 780px) {
    width: 100%;
  }
}

.react-datepicker-wrapper{
  width: 100%;
}

.bgZip {
  box-sizing: border-box;
  height: 56px;
  width: 150px;
  border: 2px solid;
  border-radius: 4px;
  border-color: #c2c6cc;
}