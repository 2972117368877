@import "./../../../../styles/variables";

@mixin divorceVerificationInfoCommons() {
  @media (max-width: 765px) {
  }
  border-radius: 40px;
  border: none;
  font-family: $font;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 30px;
  color: $textColor;
}

.divorceverification-heading {
  @include divorceVerificationInfoCommons();
  color: $textColor;
  font-family: $font;
  font-size: 32px;
  font-weight: bold;
  padding-top: 64px;
  letter-spacing: 0;
  padding-bottom: 16px;
}

.parent-both-info-padding {
  margin: 0px;
  padding-top: 50px;
  padding-bottom: 10px;
}

.divorce-verification-info {
  @include divorceVerificationInfoCommons();
  font-size: 24px;
  font-weight: 500;
  height: 28px;
  padding-bottom: 40px;
}

.birth-info-name {
  @include divorceVerificationInfoCommons();
  height: 24px;
}

.divorceverification-content {
  color: $textColor;
  font-family: $font;
  font-size: 16px;
  font-weight: 500;
}
npm .divorceverification-copies {
  height: 144px;
  width: 640px;
  color: $textColor;
  font-family: $font;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
}

.divorceverification-parent {
  color: $textColor;
  font-family: $font;
  font-size: 24px;
  font-weight: 500;
}

.birth-info-date {
  padding-bottom: 48px;
}

.btn-divorce-info {
  text-align: center;
  padding: 64px 0 104px 0;
}

.checkbox {
  box-sizing: border-box;
  height: 24px;
  width: 24px;
  border: 2px solid $checkboxBorder;
  border-radius: 4px;
  background-color: $buttonWhite;
}

.checkbox-parent {
  height: 24px;
  color: $textColor;
  font-family: $font;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  padding-left: 16px;
  text-align: center;
  float: left;
}

.add-another-format {
  height: 24px;
  color: $buttonBlue;
  font-family: $font;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 40px;
}

.add-record-type {
  cursor: pointer;
}

@media (min-width: 765px) {
  .birthInfo-container {
    margin: 0 107px 0 107px;
  }

  .birth-info-content {
    width: 640px;
  }

  .birth-info-content-section,
  .verifyinformation-div {
    width: 400px;
  }
  .next-button-birth-info {
    height: 60px;
    width: 240px;
    margin-top: 0;
    padding-left: 15px;
  }

  .back-button-birth-info {
    height: 56px;
    width: 216px;
  }
}

.customDatePickerWidth,
.customDatePickerWidth > div.react-datepicker-wrapper,
.customDatePickerWidth
  > div
  > div.react-datepicker__input-container
  .customDatePickerWidth
  > div
  > div.react-datepicker__input-container
  input {
  width: 100%;
}

