@import "./../../../../styles/variables";

.payment-desc {
  width: 640px;
  color: $textColor;
  font-family: $font;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  @media (max-width: 765px) {
    width: 327px;
  }
}

.payment-review-container {
  @media (min-width: 765px) {
    width: 684px;
  }
  @media (max-width: 765px) {
    padding: 0 24px 0 24px;
  }
}

.payment-review-heading {
  margin: 60px 0 25px 0;
  color: $textColor;
  font-family: $font;
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0;
}
.itemized-total-section {
  margin-bottom: 64px;
}

.payment-warning {
  font-weight: bolder;
}

.payment-sub-heading {
  color: $textColor;
  font-family: $font;
  font-size: 24px;
  font-weight: 500;
  margin: 30px 0 20px 0;
}

.payment-order-sub-total {
  box-shadow: inset 0 1px 0 0 #e6e6e6, inset 0 -1px 0 0 #e6e6e6;
  padding: 30px 20px 16px 20px;
}

.payment-order-sub-total-2 {
  background-color: #f6f9fd;
  padding: 30px 20px 16px 20px;
  box-shadow: inset 0 1px 0 0 #f6f9fd, inset 0 -1px 0 0 #f6f9fd;
}

.payment-font-style {
  color: $subHeading;
  font-family: $font;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
}

.payment-font-style-total {
  color: $buttonBlue;
  font-family: $font;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
}

.iframe-align {
  border: none;
}
.iframe-hide {
  display: none;
}
.payment-sub-amount {
  color: $textColor;
  font-family: $font;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  text-align: right;
}

.total-color {
  color: $buttonBlue;
  font-family: $font;
  font-size: 16px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 24px;
  text-align: right;
}

.bottom-height-adjust {
  @media (max-width: 765px) {
    height: 200px;
  }
}
