@import "./../../../../styles/variables";

@mixin birthCertificateInfoCommons() {
  @media (max-width: 765px) {
  }
  border-radius: 40px;
  border: none;
  font-family: $font;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 30px;
  color: $textColor;
}

.birth-info-heading {
  @include birthCertificateInfoCommons();
  color: $textColor;
  font-family: $font;
  font-size: 32px;
  font-weight: bold;
  padding-top: 64px;
  letter-spacing: 0;
  padding-bottom: 10px;
}

.birth-info-content-section,
.birth-info-content {
  padding-top: 20px;
}

#inputDocumentTypeFlagOne {
  display: none;
}

#inputDocumentTypeFlagTwo {
  display: none;
}

.parent-both-info-padding {
  margin: 0px;
  padding-top: 50px;
  padding-bottom: 10px;
}

.birth-personal-info {
  @include birthCertificateInfoCommons();
  font-size: 24px;
  font-weight: 500;
  height: 28px;
  padding-bottom: 20px;
}

.birth-info-name {
  @include birthCertificateInfoCommons();
  height: 24px;
}

.birth-info-input {
  @media (max-width: 765px) {
    width: 100%;
  }
}


.birth-info-label {
  color: $textColor;
  font-family: $font;
  font-size: 16px;
}
#noOfCopies,
#noOfCopies2,
#noOfCopies3 {
  width: 188px;
}

.birth-info-content {
  color: $textColor;
  font-family: $font;
  font-size: 16px;
  font-weight: 500;
  padding-top: 1;
}

.cc-form {
  padding-top: 40px;
}

.btn-birth-info {
  text-align: center;
  padding: 64px 0 104px 0;
}

.back-button-birth-info {
  @include birthCertificateInfoCommons();
  @media (max-width: 765px) {
    height: 60px;
    width: 248px;
    margin-top: 20px;
  }
  color: $buttonBlue;
  max-width: 248px;
  border: 2px solid $buttonBlue;
  background-color: $buttonWhite;
}

.checkbox {
  box-sizing: border-box;
  height: 24px;
  width: 24px;
  border: 2px solid $checkboxBorder;
  border-radius: 4px;
  background-color: $buttonWhite;
}

.checkbox-parent {
  height: 24px;
  color: $textColor;
  font-family: $font;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  padding-left: 16px;
  text-align: center;
  float: right;
}

.add-format {
  height: 100px;
  box-shadow: inset 0 -1px 0 0 $addFormat, inset 0 1px 0 0 $addFormat;
  padding-top: 30px;
  align-items: center;
}

.add-another-format {
  height: 24px;
  color: $buttonBlue;
  font-family: $font;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 40px;
}

.icon-add {
  height: 40px;
  width: 40px;
  float: right;
}

.add-record-type {
  cursor: pointer;
}

.no-of-copies {
  box-sizing: border-box;
  height: 56px;
  width: 135px;
  border: 2px solid $textboxBordercolor;
  border-radius: 4px;
}

.modal-link-color {
  color: $linkColor;
}

.modal-link-color:hover {
  color: $linkColor;
  text-decoration: underline;
}

.menu__MenuTrigger {
  cursor: pointer;
}

//modal styles

.modal-container {
  height: auto;
}
.birth-info-modal-container {
  @media (max-width: 765px) {
    padding: 0 24px 0 24px;
  }
  @media (min-width: 765px) {
    padding: 0 107px 0 0;
  }
}

.birth-info-modal-heading {
  width: 640px;
  padding: 90px 0 20px 90px;

  @media (max-width: 765px) {
    padding: 90px 0 20px 10px;
    width: 100%;
    font-size: 32px;
    color: $buttonBlue;
  }
  color: $buttonBlue;
  font-family: $font;
  font-size: 40px;
  font-weight: bold;
  letter-spacing: 0;
}

.please-note-the-diff {
  color: $textColor;
  font-family: $font;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  padding: 37px 0 37px 0;
}

.long-form-22 {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  color: $textColor;
  font-family: $font;
  padding-bottom: 20px;
}

.most-extensive-bir {
  color: $textColor;
  font-family: $font;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  padding-bottom: 20px;
}

.modal-close-button {
  text-align: center;
  padding-top: 40px;
}

.birth-personal-info {
  @media (max-width: 765px) {
    margin-bottom: 24px;
  }
}

@media (min-width: 765px) {
  .birthInfo-container,
  .long-form-22,
  .please-note-the-diff,
  .most-extensive-bir {
    margin: 0 107px 0 107px;
  }

  .birth-info-content,
  .birth-personal-info {
    width: 640px;
  }

  .modal-birth-info-button {
    width: 248px;
    height: 60px;
  }

  .birth-info-content-section,
  .gender-info,
  .add-format {
    width: 400px;
  }

  .back-button-birth-info {
    height: 56px;
    width: 216px;
  }
}

.birth-recordtype-helptext {
  width: 400px;
  color: $buttonBlue;
  font-family: $font;
  font-size: 16px;
}

.customDatePickerWidth,
.customDatePickerWidth > div.react-datepicker-wrapper,
.customDatePickerWidth
  > div
  > div.react-datepicker__input-container
  .customDatePickerWidth
  > div
  > div.react-datepicker__input-container
  input {
  width: 100%;
}

