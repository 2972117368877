@import "./../../../styles/variables";
@mixin orderRecieptCommons() {
  @media (max-width: 765px) {
    // width: 327px;
  }
  border-radius: 40px;
  border: none;
  font-family: $font;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 30px;
  color: $textColor;
}

.order-receipt-container {
  @media (max-width: 765px) {
    padding: 0 24px 0 24px;
  }
  margin-bottom: 20px;
}

@media (min-width: 765px) {
  .order-receipt-container {
    width: 640px;
    height: auto;
    padding: 0px 24px 104px 24px;
  }
  .order-review-content-section,
  .record-order-information,
  .order-field-flow {
    width: 640px;
  }
  .order-field-flow {
    height: 240px;
  }
  .order-number-date {
    padding-left: 32px;
    padding-top: 32px;
    width: 50%;
  }
  .order-number {
    float: left;
  }
  .order-date {
    float: right;
  }
  .rectangle {
    height: 20px;
    margin-left: 20px;
    margin-right: 20px;
  }
}

.order-information-container {
  border: 1px solid #dcdee1;
  border-radius: 2px;
  background-color: #ffffff;
  box-shadow: inset 0 1px 0 0 #dcdee1;
  margin: 64px 0px 64px 0px;
  padding: 30px 0 30px 24px;
}

.order-receipt-heading {
  color: #47525d;
  font-family: "Work Sans";
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 40px;
}
.order-receipt-desc {
  color: #47525d;
  font-family: "Work Sans";
  font-size: 16px;
  font-weight: 500;
}
.order-receipt {
  padding-top: 20px;
  color: #47525d;
  font-family: "Work Sans";
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 28px;
}
.order-fee-payment-details {
  padding-top: 20px;
}

.card-bg-copy {
  height: 72px;
  box-shadow: inset 0 1px 0 0 #e6e6e6, inset 0 -1px 0 0 #e6e6e6;
}
.card-bg-copy-alt {
  height: 72px;
  background-color: #f6f9fd;
  box-shadow: inset 0 1px 0 0 #f6f9fd, inset 0 -1px 0 0 #f6f9fd;
}
.feepay-data {
  color: #6c7782;
  font-family: "Work Sans";
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  padding-top: 24px;
  padding-left: 32px;
  float: left;
}

.order-number-date {
  padding-left: 20px;
  padding-top: 10px;
  color: #6c7782;
  font-family: "Work Sans";
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  padding-bottom: 10px;
}

.order-field-date {
  padding-left: 20px;
  padding-top: 20px;
  color: #6c7782;
  font-family: "Work Sans";
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  padding-bottom: 10px;
}

.order-field-detail {
  font-weight: bold;
  color: #47525d;
  margin-bottom: 10px;
  margin-left: 20px;
}
.order-field-content {
  margin-bottom: 6px;
}
.order-field-detail-paid {
  color: #304ffe;
  font-weight: bold;
  margin-bottom: 6px;
  margin-left: 20px;
}
.record-information {
  margin-top: 40px;
}
.record-order-information {
  margin-top: 20px;
  box-sizing: border-box;
  overflow: auto;
  border: 1px solid #dcdee1;
  border-radius: 4px;
}
.record-detail-information {
  margin-top: 10px;
  box-sizing: border-box;
}
.rectangle {
  height: 20px;
  box-shadow: inset 0 -1px 0 0 #dcdee1;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 10px;
  margin-bottom: 20px;
}
.printlink {
  color: #304ffe;
  font-family: "Work Sans";
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  float: left;
  padding-top: 20px;
  padding-bottom: 10px;
  padding-left: 10px;
  margin-bottom: 0px;
}
.order-feedback-text {
  height: 96px;
  color: #1f3c78;
  font-family: "Work Sans";
  font-size: 24px;
  letter-spacing: 0;
  line-height: 32px;
  padding-top: 40px;
  padding-bottom: 150px;
}
.feed-back-bg {
  background-color: #f6f9fd;
  margin-bottom: -50px;
}

@media (min-width: 765px) {
  .order-feedback-container {
    width: 640px;
    height: auto;
    padding: 0px 24px 0px 24px;
  }
}
.feedback-bold {
  font-weight: bold;
  padding-right: 8px;
}

.feedback-button {
  height: 56px;
  width: 250px;
  margin-left: -10px;
}