@import "./../../../../styles/variables";

@mixin vitalButtonCommons() {
  @media (max-width: 765px) {
    height: 60px;
    width: 248px;
  }
  border-radius: 40px;
  border: none;
  font-family: $font;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
}

.vital-top-line {
  border-top: 1px solid $checkboxBorder;
}

.vital-record-heading {
  color: $textColor;
  font-family: $font;
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 40px;
  padding-top: 70px;

  @media (max-width: 765px) {
    padding-bottom: 16px;
    width: 317px;
  }
}

.vital-record-sub-heading {
  color: $textColor;
  font-family: $font;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  padding-bottom: 0;
}
.section-headings {
  color: $textColor;
  font-family: $font;
  font-size: 26px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 32px;
  padding-bottom: 8px;
  padding-top: 46px;
}
.vital-record-container {
  @media (max-width: 765px) {
    padding: 0 24px 0 24px;
  }
}
.req-vital-birth {
  box-sizing: border-box;
  box-shadow: 0 8px 40px 0 rgba(0,0,0,0.1);
  @media (max-width: 765px) {
  }
  border: 2px solid $checkboxBorder;
  box-shadow: 0 8px 40px 0 rgba(0,0,0,0.1);
  border-radius: 4px;
  background-color: $buttonWhite;
  margin: 0;
  margin-bottom: 24px;
}
.req-vital-birth:hover {
  border: 2px solid $buttonBlue;
  cursor: pointer;
}

.vital-section-titles {
  padding: 22px 0 9px 0px;
  color: $textColor;
  font-family: $font;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
}

.vital-section-desc {
  color: $textColor;
  font-family: $font;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
}

.parent-info-padding {
  padding-bottom: 80px;
}

.select-vital-button {
  @include vitalButtonCommons();
  background-color: $buttonBlue;
  color: $buttonWhite;
  @media (min-width: 765px) {
    margin: 58px 32px 58px 16px;
  }
  margin: 34px 0 24px 0;
  height: 56px;
  width: 136px;
}

.vital-back-button {
  @include vitalButtonCommons();
  color: $buttonBlue;
  border: 2px solid $buttonBlue;
  background-color: $buttonWhite;
  @media (min-width: 765px) {
    height: 56px;
    width: 160px;
  }
}

.vital-button-align {
  text-align: center;
}

.vital-back-button-align {
  text-align: center;
  margin: 64px 0 104px 0;
  @media (min-width: 765px) {
    margin: 64px 886px 0 0;
  }
}

@media (min-width: 765px) {
  .vital-record-container {
    width: 684px;
    padding: 0px 24px 104px 24px;
  }

  .vital-record-heading {
    padding-bottom: 10px;
  }
  .req-vital-birth {
    height: auto;
  }
}
@media (max-width: 765px){
  .height-fix-mobile{
    height: 240px;
  }
}