@import "./../../../styles/variables";
@mixin orderStatusCommons() {
  @media (max-width: 765px) {
  }
}
.status-container {
  padding: 0 29px 0 24px;
}

.order-status-heading {
  @include orderStatusCommons();
  color: $textColor;
  font-family: $font;
  font-size: 32px;
  font-weight: bold;
  padding-top: 64px;
  letter-spacing: 0;
  padding-bottom: 16px;
}

.order-info-name {
  border-radius: 40px;
  border: none;
  font-family: $font;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  color: $textColor;
  padding-top: 20px;
  @media (min-width: 765px) {
    padding-bottom: 20px;
    height: 24px;
    line-height: 30px;
  }
}

.order-input-align {
  padding-bottom: 10px;
}

.order-page-content {
  color: $textColor;
  font-family: $font;
  font-size: 20px;
  font-weight: 500;
  padding-top: 30px;
  text-align: left;
}

.status-border {
  height: 20px;
  box-shadow: inset 0 -2px 0 0 $checkboxBorder;
  margin-right: 20px;
}

.status-link-color {
  text-decoration: underline;
  padding-bottom: 20px;
  cursor: pointer;
  padding-left: 0px;
}

.order-status-padding {
  padding-bottom: 100px;
}

.order-found-padding {
  padding-bottom: 60px;
}

.alternate-way-button,
.alternate-way-button:hover,
.alternate-way-button:active,
.alternate-way-button:focus {
  background-color: white;
  border-color: white;
  color: $linkColor;
}

.status-input {
  color: $textColor;
  font-family: $font;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
}

.order-info {
  @include orderStatusCommons();
  font-size: 24px;
  font-weight: 500;
  height: auto;
  font-family: $font;
  color: $textColor;
}

.order-found {
  @include orderStatusCommons();
  font-size: 24px;
  font-weight: 500;
  height: auto;
  padding-top: 40px;
}

.status-submit-button {
  @include orderStatusCommons();
  @media (max-width: 765px) {
    height: 60px;
    width: 160px;
  }
  box-sizing: border-box;
  background-color: $buttonBlue;
  color: $buttonWhite;
  margin-top: 16px;
  border-radius: 40px;
}

.label-padding {
  padding-top: 30px;
  padding-bottom: 30px;
}

.field-size {
  @include orderStatusCommons();
  height: auto;
  margin-bottom: 40px;
}

.order-suspended {
  color: #46525e;
  font-family: $font;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
}

@media (min-width:765px) {
  .status-container {
    width: 684px;
  }
  .order-info-content-section,
  .field-size {
    width: 400px;
  }

  .order-status-heading {
    height: auto;
    width: 684px;
    font-size: 40px;
    letter-spacing: 0;
    line-height: 40px;
    padding-bottom: 10px;
  }

  .status-submit-button {
    height: auto;
    width: 180px;
    margin-top: 0;
    float: left;
  }

  .btn-birth {
    text-align: center;
    padding: 64px 0 104px 0;
  }

  .next-width {
    width: 200px;
    float: left;
    margin-top: 16px;
  }

  .order-found-flow {
    margin-top: 60px;
    box-sizing: border-box;
    border: 1px solid #dcdee1;
    border-radius: 4px;
    overflow: hidden;
  }

  .order-found-container {
    background-color: $buttonWhite;
    margin: 30px 0px 32px 0px;
    padding: 0px 0 0px 22px;
    overflow: hidden;
  }

  .express-shipping-ex {
    color: #405362;
    font-family: $font;
    font-size: 16px;
    font-style: italic;
    letter-spacing: 0;
    line-height: 24px;
    margin: 0px;
  }
  .express-shipping-rw {
    padding-left: 15px;
  }

  .next-width-order {
    width: 240px;
    float: left;
  }

  .found-text-order{
  margin-top: 1em ;
  margin-bottom: 1em ;
  }

  .select-order-align {
    padding-bottom: 30px;
  }

  .orderfound-back-button {
    @include orderStatusCommons();
    color: $buttonBlue;
    border: 2px solid $buttonBlue;
    background-color: $buttonWhite;
    @media (min-width: 765px) {
      height: 56px;
      width: 235px;
    }
  }

  .order-status-found-content {
    margin-bottom: 6px;
    margin-left: 20px;
  }
}
