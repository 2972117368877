@import "./../../../../styles/variables";

@mixin birthVerificationInfoCommons() {
  @media (max-width: 765px) {
  }
  border-radius: 40px;
  border: none;
  font-family: $font;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 30px;
  color: $textColor;
}

.birthverification-heading {
  @include birthVerificationInfoCommons();
  color: $textColor;
  font-family: $font;
  font-size: 32px;
  font-weight: bold;
  padding-top: 64px;
  letter-spacing: 0;
  padding-bottom: 16px;
}

.parent-both-info-padding {
  margin: 0px;
  padding-top: 50px;
  padding-bottom: 10px;
}

.birth-verification-info {
  @include birthVerificationInfoCommons();
  font-size: 24px;
  font-weight: 500;
  height: 28px;
  padding-top: 40px;
  padding-bottom: 40px;
}

.birth-info-name {
  @include birthVerificationInfoCommons();
  height: 24px;
  padding-top: 20px;
}

.birthverification-info-input {
  @media (max-width: 765px) {
    width: 345px;
  }
}
.birthverification-info-input-disabled {
  @media (max-width: 765px) {
    width: 345px;
  }
  text-decoration-color: $textColor;
  font-weight: bold;
}

.birthverification-content {
  color: $textColor;
  font-family: $font;
  font-size: 24px;
  font-weight: 500;
  padding-top: 1;
}
.birthverification-parent {
  color: $textColor;
  font-family: $font;
  font-size: 24px;
  font-weight: 500;
  padding-top: 40px;
}

.btn-birth-info {
  text-align: center;
  padding: 64px 0 104px 0;
}

.back-button-birthverification {
  @include birthVerificationInfoCommons();
  @media (max-width: 765px) {
    height: 60px;
    width: 248px;
  }
  color: $buttonBlue;
  max-width: 248px;
  border: 2px solid $buttonBlue;
  background-color: $buttonWhite;
}

.checkbox {
  box-sizing: border-box;
  height: 24px;
  width: 24px;
  border: 2px solid $checkboxBorder;
  border-radius: 4px;
  background-color: $buttonWhite;
}

.checkbox-parent {
  height: 24px;
  color: $textColor;
  font-family: $font;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  padding-left: 16px;
  text-align: center;
  float: left;
}

.add-another-format {
  height: 24px;
  color: $buttonBlue;
  font-family: $font;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 40px;
}

.add-record-type {
  cursor: pointer;
}

@media (min-width: 765px) {
  .birthInfo-container{
    margin: 0 107px 0 107px;
  }

  .birth-info-content {
    width: 640px;
  }

  .birth-info-content-section,
  .verifyinformation-div {
    width: 400px;
  }

  .back-button-birthverification {
    height: 56px;
    width: 216px;
  }
}

.customDatePickerWidth,
.customDatePickerWidth > div.react-datepicker-wrapper,
.customDatePickerWidth
  > div
  > div.react-datepicker__input-container
  .customDatePickerWidth
  > div
  > div.react-datepicker__input-container
  input {
  width: 100%;
}
