@import "./../../styles/variables";
.error-page-content {
  color: $textColor;
  font-family: $font;
  font-size: 16px;
  font-weight: 500;
  padding-top: 20px;
}

.error-padding {
  padding-top: 10px;
  padding-bottom: 480px;
}

.error-common-heading {
  color: $buttonBlue;
  font-family: $font;
  font-size: 32px;
  font-weight: bold;
  padding-top: 64px;
  letter-spacing: 0;
  padding-bottom: 16px;
}

.error-button-secondary {
  // height: 60px;
  width: 240px;
  font-size: 18px;
  margin-top: 70px;
  padding: 20 59 20 59 !important;
}

@media (min-width: 765px) {

  .error-modal {
    color: $linkColor;
    font-family: $font;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    padding: 37px 0 37px 0;
  }

  .error-page-button {
    width: 320px;
    margin-top: 20px;
  }
}