$textColor: #47525d; 
$linkColor: blue;
$headerBackgroundColor:  #F7F8FA;
$blueStrip:#1f3c78;
$headerRed:#b41f29;
$subHeading: #6C7782;
$pageHeading: #47525D;
$font: "Work Sans";
$buttonBlue: #304ffe;
$buttonWhite: #ffffff;
$textboxBordercolor: #c2c6cc;
$errorinfocolor: red;
$modalTextColor: #667887;
$blue: #3D43FF;
$yellow: #f8bb40;
$errorText: #283847;
$checkboxBorder :#c2c6cc;
$errorBorderColor:#d2082d;
$addFormat: #e6e6e6;
$orderSubTotal: #f6f9fd;
