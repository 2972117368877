@import "./../../../../styles/variables";

.select-align-death {
  padding-bottom: 32px;
}

.death-relation {
  height: 80px;
}

.death-date-content {
  color: $textColor;
  font-family: $font;
  font-size: 16px;
  font-weight: 500;
  padding-top: 30px;
}
