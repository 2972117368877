@import "./../../../../styles/variables";

@mixin deathVerificationInfoCommons() {
  @media (max-width: 765px) {
  }
  border-radius: 40px;
  border: none;
  font-family: $font;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 30px;
  color: $textColor;
}

.deathverification-heading {
  @include deathVerificationInfoCommons();
  color: $textColor;
  font-family: $font;
  font-size: 32px;
  font-weight: bold;
  padding-top: 64px;
  letter-spacing: 0;
  padding-bottom: 16px;
}

.death-verification-info {
  @include deathVerificationInfoCommons();
  font-size: 24px;
  font-weight: 500;
  height: 28px;
  padding-bottom: 40px;
}

.birth-info-name {
  @include deathVerificationInfoCommons();
  height: 24px;
  padding-top: 20px;
}

.deathverification-content {
  color: $textColor;
  font-family: $font;
  font-size: 24px;
  font-weight: 500;
  padding-top: 1;
}
.deathverification-parent {
  color: $textColor;
  font-family: $font;
  font-size: 24px;
  font-weight: 500;
  padding-top: 40px;
}

.birth-info-date {
  padding-bottom: 20px;
}

.county-death {
  padding-bottom: 20px;
  padding-top: 32px;
}

.btn-death-info {
  text-align: center;
  padding: 64px 0 104px 0;
}

.back-button-deathverification {
  @include deathVerificationInfoCommons();
  @media (max-width: 765px) {
    height: 60px;
    width: 248px;
  }
  color: $buttonBlue;
  max-width: 248px;
  border: 2px solid $buttonBlue;
  background-color: $buttonWhite;
}

.checkbox {
  box-sizing: border-box;
  height: 24px;
  width: 24px;
  border: 2px solid $checkboxBorder;
  border-radius: 4px;
  background-color: $buttonWhite;
}

.checkbox-parent {
  height: 24px;
  color: $textColor;
  font-family: $font;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  padding-left: 16px;
  text-align: center;
  float: left;
}

@media (min-width: 765px) {
  .back-button-deathverification {
    height: 56px;
    width: 216px;
  }
}

.record-details-padding {
  padding-top: 16px;
}
