@import "./../../../../styles/variables";

@mixin birthCertificateInfoCommons() {
  @media (max-width: 765px) {
  }
  border-radius: 40px;
  border: none;
  font-family: $font;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 30px;
  color: $textColor;
}

.birthInfo-container {
  padding: 0 24px 0 24px;
}

.birth-info-heading {
  @include birthCertificateInfoCommons();
  color: $textColor;
  font-family: $font;
  font-size: 32px;
  font-weight: bold;
  padding-top: 64px;
  letter-spacing: 0;
  padding-bottom: 16px;
}

.birth-info-content-section {
  padding-top: 32px;
}

.parent-both-info-padding {
  margin: 0px;
  padding-top: 50px;
  padding-bottom: 10px;
}

.birth-info-name {
  @include birthCertificateInfoCommons();
  height: 24px;
}

.birth-info-content {
  color: $textColor;
  font-family: $font;
  font-size: 16px;
  font-weight: 500;
  padding-top: 1;
}

.birth-info-date {
  padding-bottom: 48px;
}

.btn-birth-info {
  text-align: center;
  padding: 64px 0 104px 0;
}

.back-button-birth-info {
  @include birthCertificateInfoCommons();
  @media (max-width: 765px) {
    height: 60px;
    width: 248px;
    margin-top: 20px;
  }
  color: $buttonBlue;
  max-width: 248px;
  border: 2px solid $buttonBlue;
  background-color: $buttonWhite;
}

.checkbox {
  box-sizing: border-box;
  height: 24px;
  width: 24px;
  border: 2px solid $checkboxBorder;
  border-radius: 4px;
  background-color: $buttonWhite;
}

.checkbox-parent {
  height: 24px;
  color: $textColor;
  font-family: $font;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  padding-left: 16px;
  text-align: center;
  float: right;
}

.add-another-format {
  height: 24px;
  color: $buttonBlue;
  font-family: $font;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 40px;
}

.icon-add {
  height: 40px;
  width: 40px;
  float: right;
}

.add-record-type {
  cursor: pointer;
}

.no-of-copies {
  box-sizing: border-box;
  height: 56px;
  width: 135px;
  border: 2px solid $textboxBordercolor;
  border-radius: 4px;
}

.modal-link-color {
  color: $linkColor;
}

.modal-link-color:hover {
  color: $linkColor;
  text-decoration: underline;
}

.menu__MenuTrigger {
  cursor: pointer;
}

.background {
  height: 1023px;
}

@media (min-width: 765px) {
  .birthInfo-container {
    margin: 0 107px 0 107px;
  }

  .birth-info-content {
    width: 640px;
  }

  .birth-info-content-section {
    width: 400px;
  }

  .back-button-birth-info {
    height: 56px;
    width: 216px;
  }
}

.customDatePickerWidth,
.customDatePickerWidth > div.react-datepicker-wrapper,
.customDatePickerWidth
  > div
  > div.react-datepicker__input-container
  .customDatePickerWidth
  > div
  > div.react-datepicker__input-container
  input {
  width: 100%;
}
