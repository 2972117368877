@import "./../../../styles/variables";

@mixin orderReviewCommons() {
  @media (max-width: 765px) {
    // width: 327px;
  }
  border-radius: 40px;
  border: none;
  font-family: $font;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 30px;
  color: $textColor;
}

.order-review-container {
  @media (min-width: 765px) {
    width: 684px;
  }
  @media (max-width: 765px) {
    padding: 0 24px 0 24px;
  }
}

.order-info-container {
  border: 1px solid #dcdee1;
  border-radius: 2px;
  background-color: $buttonWhite;
  box-shadow: inset 0 1px 0 0 #dcdee1;
  margin: 0px 12px 64px 3px;
  padding: 30px 0 30px 24px;
}

.order-review-heading {
  margin: 60px 0 25px 0;
  color:$textColor;
  font-family: $font;
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0;
}

.order-review-desc,
.order-review-donation {
  color:$textColor;
  font-family: $font;
  font-size: 16px;
  font-weight: 500;
}

.order-review-donation {
  margin-left: 10px;
  padding-left: 30px;
}

.order-sub-heading {
  color: $textColor;
  font-family: $font;
  font-size: 24px;
  font-weight: 500;
  margin: 30px 0 20px 0;
}

.info-titles {
  color: #6c7782;
  font-family: $font;
  font-size: 16px;
  margin-left: 20px;
}

.order-review-links {
  padding-top: 20px;
  font-family: $font;
  text-decoration: underline;
  color: $buttonBlue;
  margin-left: 20px;
}
.order-delete {
  padding-left: 30px;
  color: $errorBorderColor;
}

.order-edit {
  color: $buttonBlue;
}

.info-titles-value {
  color: $textColor;
  font-family: $font;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0px;
  margin-left: 20px;
}
.order-add-another-record {
  border-radius: 40px;
  border: none;
  font-family: $font;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 30px;
  color: $textColor;
  height: 56px;
  width: 216px;
  color: $buttonBlue;
  max-width: 248px;
  border: 2px solid $buttonBlue;
  background-color: $buttonWhite;
  margin-bottom: 60px;
}

.back-button-order {
  @include orderReviewCommons();
  @media (max-width: 765px) {
    height: 60px;
    width: 248px;
  }
  color: $buttonBlue;
  max-width: 248px;
  border: 2px solid $buttonBlue;
  background-color: $buttonWhite;
}

.next-button-order {
  @include orderReviewCommons();
  @media (max-width: 765px) {
    height: 60px;
    width: 248px;
  }
  box-sizing: border-box;
  background-color: $buttonBlue;
  color: $buttonWhite;
  margin-top: 16px;
}

@media (min-width: 765px) {
  .next-button-order {
    margin-top: 0;
  }
  .back-button-order,
  .next-button-order {
    height: 56px;
    width: 160px;
  }
}
.order-button-record-align {
  @media (max-width: 765px) {
    text-align: center;
  }
}

.order-review-button-align {
  @media (min-width: 765px) {
    padding-top: 50px;
  }
  @media (max-width: 765px) {
    padding: 50px 0 90px 0;
  }
}

.modalPopUp {
  @media (max-width: 765px) {
    height: 504px;
    width: 637px;
  }
  border-radius: 4px;
  background-color: $buttonWhite;
}

.modal-container-order-delete {
  text-align: center;
}

.modal-heading-order-delete {
  text-align: center;
  color: $blue;
  font-family: $font;
  font-size: 40px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 48px;
  text-align: center;
  padding-top: 50px;
}

.modal-desc-order-delete {
  margin-top: 25px;
  margin-bottom: 50px;
  color: $modalTextColor;
  font-family: $font;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  padding: 5px 70px 35px 70px;
}

.modalLink {
  padding-top: 28px;
  margin-bottom: 50px;
}

.modalLinkColor {
  color: $blue;
}
