@import "./../../../styles/variables";
.error-page-content {
  color: $textColor;
  font-family: $font;
  font-size: 16px;
  font-weight: 500;
  padding-top: 20px;
}

.error-padding {
  padding-top: 10px;
  padding-bottom: 480px;
}

.error-heading {
  color: $textColor;
  font-family: $font;
  font-size: 32px;
  font-weight: bold;
  padding-top: 64px;
  letter-spacing: 0;
  padding-bottom: 16px;
}
