@import "./../../../styles/variables";
@mixin landingPageCommons() {
  @media (max-width: 765px) {
    // width: 319px;
  }

  font-family: $font;
  color: $textColor;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
}

.first-container {
  padding: 0 24px 0 24px;
  @media (max-width: 765px) {
    // width: 319px;
   overflow: auto;
  }
}
.section-info {
  height: 1106px;
}
.page-heading {
  @include landingPageCommons();
  color: $pageHeading;
  font-size: 40px;
  font-family: $font;
  line-height: 48px;
  font-weight: bold;
}

.link-color {
  color: $linkColor;
}

.para-4 {
  @include landingPageCommons();
  font-size: 16px;
  padding: 20px 0 20px 0;
  margin: 20px 0 10px 0;
}
.para-1,
.para-2,
.para-3,
.para-5,
.para-6,
.para-8 {
  @include landingPageCommons();
  font-size: 16px;
  padding: 10px 0 10px 0;
  margin: 0px;
}

.para-1
{
@include landingPageCommons();
  font-size: 20px;
  padding: 10px 0 10px 0;
  margin: 0px;
}

ul {
  margin: 0;
  list-style-type: disc;
}

.para-space-align {
  margin-bottom: 0;
}

.button-group {
  padding: 64px 0 104px 0;
  @media(max-width: 765px){
    text-align: center;
  }
}
.landing-check-order {
  @include landingPageCommons();
  border-radius: 40px;
  color: $buttonBlue;
  border: 2px solid $buttonBlue;
  background-color: $buttonWhite;
  width: 248px;
  height: 56px;
  font-weight: 500;
}

.landing-page-button{
  height: 56px;
  width: 184px;
  font-size: 16px;
  text-align: center;
  font-weight: 500;
  @media(max-width: 765px){
    width: 248px;
    margin-top: 20px;
  }
}

.landing-padding{
  padding-top: 15px;
  padding-bottom: 15px;
}

@media (min-width: 765px) {
  .vital-record-section {
    margin-top: 60px;
  }

  .first-container {
    width: 684px;
  }
  .page-heading {
    width: 684px;
    font-size: 56px;
    letter-spacing: 0;
    line-height: 50px;
    margin-top: 80px;
    padding-bottom: 10px;
  }

  .landing-check-order {
    height: 56px;
    width: 248px;
    font-weight: 500;
  }
  .section-info {
    height: auto;
  }
}