@import "./../styles/variables";
@media (max-width: 765px) {
  .footer {
    background-color: $blueStrip;
  }

  .tx-footer-logo {
    height: 32px;
    padding: 64px 0 18.24px 24px;
    box-sizing: content-box;
  }

  .footer-title {
    color: $buttonWhite;
    font-family: $font;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 20px;
    padding-left: 24px;
  }
  .modal-close-button {
    margin-bottom: 268px;
  }
  .contact-modal-container {
    padding: 0 24px 50px 24px;
  }
  .footer-link {
    color: $buttonWhite;
    font-family: $font;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    padding-bottom: 34px;
    line-height: 20px;
    display: block;
  }
  .footer-link-section {
    padding: 64px 0 64px 41px;
  }
}

.contact-modal-heading-1 {
  width: 640px;
  padding: 90px 0 20px 90px;

  @media (max-width: 765px) {
    padding: 90px 0 20px 10px;
    width: 100%;
    font-size: 32px;
    color: $buttonBlue;
  }
  color: $buttonBlue;
  font-family: "Work Sans";
  font-size: 40px;
  font-weight: bold;
  letter-spacing: 0;
}


@media (min-width: 765px) {
  .footer {
    margin: auto;
    background-color: $blueStrip;
    white-space: nowrap;
  }

 
  .modal-contact-button {
    width: 248px;
    height: 60px;
  }

  .tx-footer-logo {
    height: 32px;
    padding: 20px 20px 20px 20px;
    box-sizing: content-box;
  }

  .footer-item {
    display: inline-block;
  }

  .modal-close-button {
    margin-bottom: 128px;
  }
  .footer-title {
    font-family: $font;
    color: $buttonWhite;
    font-size: 14px;
    font-weight: 500;
    padding-right: 50%;
    padding: 27px 0 0 0;
    display: inline-block;
  }

  .footer-link-section {
    text-align: right;
    padding-top: 24px;
    white-space: nowrap;
  }

  .footer-link {
    color: $buttonWhite;
    font-family: $font;
    padding-right: 40px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 20px;
    align-items: center;
    vertical-align: middle;
  }

  .contact-modal-container {
    padding: 0 107px 50px 107px;
  }

  .contact-modal {
    color: $linkColor;
    font-family: $font;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0;
    padding: 37px 0 37px 0;
  }

  .contact-modal-text {
    color: $textColor;
    font-family: $font;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0;
    padding-top: 20px;
  }

  .contact-modal-tool-text {
    color: $linkColor;
    margin-left: 75px;
  }

  .contact-modal-order-number {
    margin-left: 1px;
  }

  .contact-modal-confirmation-text {
    margin-left: -255px;
  }

  .contact-modal-order-confirmation-text {
    color: $linkColor;
    margin-left: -280px;
  }

  .contact-modal-order-review-text {
    margin-left: -32px;
  }

  .contact-modal-order-statistics-text {
    color: $linkColor;
    margin-left: -467px;
  }
}
