@import "../../../../styles/variables";

@mixin verifyIdentityCommons() {
  @media (max-width: 765px) {
    width: 100%;
  }
  border-radius: 40px;
  border: none;
  font-family: $font;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 30px;
  color: $textColor;
}
.verify-container {
  padding: 0 24px 0 24px;
}

.verify-heading {
  @include verifyIdentityCommons();
  margin: 0px;
  // height: 40px;
  font-size: 32px;
  font-weight: bold;
  line-height: 40px;
}
.verify-content-section {
  padding-top: 32px;
  width: inherit;
}
.verify-description {
  @include verifyIdentityCommons();
  // height: 72px;
  padding-top: 20px;
}

.personal-information-div {
  margin: 0px;
  padding-top: 10px;
}

.personal-information {
  @include verifyIdentityCommons();
  font-size: 24px;
  font-weight: 500;
  height: 28px;
}

.name {
  @include verifyIdentityCommons();
}

.bg {
  @include verifyIdentityCommons();
  box-sizing: border-box;
  height: 56px;
  border: 2px solid $textboxBordercolor;
  border-radius: 4px;
}

.answer {
  color: $textColor;
  font-family: $font;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
}

.bgZipCode {
  // box-sizing: border-box;
  height: 56px;
  width: 135px;
  //border: 2px solid $textboxBordercolor;
  border-radius: 4px;
  padding-top: 10px;
  color: $textColor;
  font-family: $font;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
}


.bgState {
  box-sizing: border-box;
  height: 56px;
  border: 2px $textboxBordercolor;
  border-radius: 4px;
}

.bg1 {
  margin-top: 8px;
}

.state-id-or-dl {
  height: 160px;
}

.texas-driver-license {
  @include verifyIdentityCommons();
  height: 40px;
  font-size: 24px;
  font-weight: 500;
  line-height: 30px;
}

.texas-dl-description {
  @include verifyIdentityCommons();
  height: 24px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 40px;
  padding-top: 40px;
}

.real-id-2020 {
  height: 24px;
  color: $textColor;
  font-family: $font;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: 40px;
}

.texas-dl-visual {
  @media (max-width: 765px) {
    max-width: 337px;
  }
}

.new-address-copy {
  margin-top: 64px;
}

.DoB-date {
  padding-bottom: 32px;
}

.input-align {
  padding-bottom: 32px;
}

.text-field {
  height: 152px;
}

.text-field-city {
  margin-top: 32px;
  margin-bottom: 32px;
}

.text-field-zip {
  display: inline-grid;
  padding-top: 16px;
  margin-bottom: 60px;
}

.text-field-previouszip {
  display: inline-grid;
  padding-top: 24px;
  margin-bottom: 60px;
}

.text-field-copy-3 {
  height: 88px;
  margin-top: 40px;
}

.btn-verify {
  text-align: center;
  padding: 64px 0 104px 0;
}

.back-button-verify {
  @include verifyIdentityCommons();
  @media (max-width: 765px) {
    height: 60px;
    width: 248px;
    margin-top: 20px;
  }
  color: $buttonBlue;
  max-width: 248px;
  border: 2px solid $buttonBlue;
  background-color: $buttonWhite;
}

.next-button-verify {
  @include verifyIdentityCommons();
  @media (max-width: 765px) {
    height: 60px;
    width: 248px;
    margin-top: 20px;
  }
  box-sizing: border-box;
  background-color: $buttonBlue;
  color: $buttonWhite;
  margin-top: 16px;
}

// .react-datepicker-wrapper {
//   border: 2px solid #c2c6cc !important;
// }

// .react-datepicker__input-container input {
//   // margin-left:4px;
//   // margin-right:2px;
// }
// .react-datepicker-wrapper,
// .react-datepicker__input-container,
// .react-datepicker__input-container input {
//   display: block;
//   width: 100%;
//   border-radius: 4px;
//   min-height: 56px;
//   border: none;
// }

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.texas-dl-visual {
  @media (max-width: 765px) {
    height: 303px;
    width: 327px;
  }
}

@media (min-width:765px) {
  .verify-content-section {
    width: 684px;
  }
  .next-button-verify {
    margin-top: 0;
  }
  .back-button-verify,
  .next-button-verify {
    height: 56px;
    width: 160px;
  }
  .bg,
  .cc-form,
  .DoB-date,
  .bgState {
    width: 400px;
  }
}
