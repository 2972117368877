@import "./../styles/_variables";

.btn-link {
  color: #1f3c78;
  font-family: $font;
  font-size: 14px;
  font-weight: bold;
}
.lang-selector {
  text-align: left;
  white-space: nowrap;

  @media (max-width: 765px) {
    display: inline-block;
    padding: 22px 10px 24px 0;
  }
}

.header-background {
  background-color: #f7f8fa;
}

.translate-links {
  color: #575757;
  font-family: $font;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.88px;
  padding: 10px;
}
.translate-links-active {
  color: #1f3c78;
  font-family: $font;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.88px;
  padding: 10px;
}
.common-subheading {
  padding: 0 0 0 24px;
}

@media (max-width: 765px) {
  .header {
    background-color: $headerBackgroundColor;
    box-shadow: inset 0 -1px 0 0 #eeeff1;
  }

  .logo-dshs {
    height: 40px;
  }

  .heading {
    padding: 20px 0 9px 0px;
    width: 140px;
    color: $blueStrip;
    font-family: $font;
    font-size: 14px;
    font-weight: bold;
  }

  .item {
    vertical-align: middle;
  }

  .header-bluestrip {
    height: 8px;
    background-color: $blueStrip;
  }

  .header-redstrip {
    height: 8px;
    background-color: $headerRed;
  }

  .sub-heading {
    padding: 24px 0 24px 0;
    font-family: $font;
    color: $subHeading;
    font-size: 14px;
    margin-bottom: 0;
  }
}
@media (min-width: 765px) {
  .header {
    margin: auto;
    background-color: $headerBackgroundColor;
    height: 72px;
    white-space: nowrap;
  }
  .header-1 {
    white-space: nowrap;
  }
  .common-subheading {
    width: 684px;
  }

  .item {
    vertical-align: middle;
  }

  .logo-dshs {
    height: 50px;
  }
  .lang-selector {
    padding: 22px 24px 24px 0;
    text-align: right;
  }

  .heading {
    font-family: $font;
    color: $blueStrip;
    font-size: 16px;
    font-weight: bold;
    padding: 24px 0 20px 10px;
  }

  .header-bluestrip {
    height: 8px;
    background-color: $blueStrip;
  }

  .header-redstrip {
    height: 8px;
    background-color: $headerRed;
  }

  .sub-heading {
    padding: 24px 0 24px 0;
    font-family: $font;
    color: $subHeading;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
    margin-bottom: 0;
  }
}
