.progress {
  height: 2px;
}

.progress-bar {
  height: 1px;
  border: 2px solid #304ffe;
}

.progress-step {
  height: 24px;
  // width: 84px;
  color: #304ffe;
  font-family: "Work Sans";
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: 20px;
}
