@import "./../../styles/variables";

.error-page-button-primary {
  margin-top: 76px;
  height: 53px;
  width: 240px;
}

.button-align-errorpage {
  @media (max-width: 765px) {
    text-align: center;
  }
}

.error-page-heading {
  padding: 65px 0 25px 0;
  
  color: $buttonBlue;
  font-family: $font;
  font-size: 32px;
  font-weight: bold;
}

.error-page-description {
  padding-top: 25px;
 
  color: $textColor;
  font-family: $font;
  font-size: 16px;
  font-weight: 500;
}
